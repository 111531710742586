export const StatutConventionnement = {
  CONVENTIONNEMENT_EN_COURS: 'CONVENTIONNEMENT_EN_COURS',
  CONVENTIONNEMENT_VALIDÉ: 'CONVENTIONNEMENT_VALIDÉ',
  RECONVENTIONNEMENT_INITIÉ: 'RECONVENTIONNEMENT_INITIÉ',
  RECONVENTIONNEMENT_VALIDÉ: 'RECONVENTIONNEMENT_VALIDÉ',
  NON_INTERESSÉ: 'NON_INTERESSÉ',
  CONVENTIONNEMENT_VALIDÉ_PHASE_2: 'CONVENTIONNEMENT_VALIDÉ_PHASE_2',
};

export const StatutCoselec = {
  POSITIF: 'POSITIF',
  NÉGATIF: 'NÉGATIF',
};

export const PhaseConventionnement = {
  PHASE_1: '1',
  PHASE_2: '2',
};

export const StatutsStructuresActives = ['VALIDATION_COSELEC'];
